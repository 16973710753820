<template>
	<div class="container">
		<div class="w1200">
			<!-- <div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > 网上祭扫</div> -->
			<el-carousel :interval="5000" arrow="always">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<div class="banner" @click="toBannerDetail(item,index)"><img :src="item.pic"/></div>
				</el-carousel-item>
			</el-carousel>
			<div class="wapper">
				<!-- <div class="condition flex_a">
					分类
					<el-select v-model="classify" placeholder="">
						<el-option
						v-for="item in classifyItem"
						:key="item.value"
						:label="item.label"
						:value="item.value">
						</el-option>
					</el-select>
					关键词
					<el-input v-model="keyword"></el-input>
					<img src="@/assets/images/shaixuan.png" alt="">
				</div> -->
				<el-row :gutter="20">
					<el-col :span="6" v-for="(item,index) in list" :key="index" >
						<div class="imgItem" @click="toDetail(item.id)">
							<div class="imgBorder">
								<img :src="item.cover ? item.cover : noImg"/>
							</div>
							<div class="info">
								<p class="line1">{{item.name}}</p>
								<p class="line1">（{{item.birthDeath}}）</p>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-pagination
					background
					v-if="total > 0"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageNo"
					:page-size="pageSize"
					layout="total, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
				
			</div>
				
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				keyword: '',
				classify: '',
				pageNo: 1,
				pageSize: 8,
				noImg: require('@/assets/images/moren.png'),
				list: [],
				total: null,
				bannerList: [],
			}
		},
		created() {
			document.title = '网上祭扫'
			
		},
		mounted() {
			this.getBanner()
			this.getList()
		},
		methods: {
			getBanner() {
				let formData = new FormData()
					formData.append('type', 2)
				this.$http({
					url: `/bannersApi/queryBannersPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						if(res.data[0]){
							this.bannerList = res.data
						}
					}
				});
			},
			getList() {
				let formData = new FormData()
				formData.append('page', this.pageNo)
				formData.append('limit', this.pageSize)
				this.$http({
					url: `/martyrsApi/queryMartyrsPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					this.total = res.count
					this.list = res.data
				});
			},
			toBannerDetail(item,index) {
				if(item.includeContent == 1){
					this.$router.push({ path: "/sweep/bannerDetail",query:{index:index}});
				}
			},
			toDetail(id) {
				this.$router.push({ path: "/sweep/detail",query:{ id: id }});
			},
			handleChangeTab(index) {
				this.tabIndex = index
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.getList()
			},
			handleCurrentChange(val) {
				this.pageNo = val
				this.getList()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		background: #FFF;
		.banner{
			img{
				height: 400px;
				width: 100%;
			}
		}
		.wapper{
			padding: 30px 60px;
			.condition{
				font-size: 16px;
				color: #595E63;
				margin-bottom: 10px;
				.el-input{
					width: 160px;
					margin-left: 20px;
				}
				.el-select{
					width: 160px;
					margin-left: 20px;
					margin-right: 30px;
				}
				img{
					width: 38px;
					height: 38px;
					margin-left: 20px;
					cursor: pointer;
				}
			}
			.el-col{
				margin-top: 20px;
			}
			.imgItem{
				cursor: pointer;
				font-size: 16px;
				.imgBorder{
					border: 2px solid #A5343C;
					padding: 20px;
					img{
						width: 215px;
						height: 260px;
					}
				}
				
				.info{
					background: #D5BB74;
					color: #FFF;
					padding: 8px 0;
					text-align: center;
					color: #020101;
					p{
						&:nth-of-type(1){
							font-size: 24px;
							font-weight: bold;
							margin-bottom: 5px;
						}
						&:nth-of-type(2){
							font-size: 18px;
						}
						
					}
				}
			}
			

		}
	}
	
	
	
}
	
</style>
